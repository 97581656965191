import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import clsx from "clsx";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	Container,
	Fade,
	Grid,
	Typography,
	withStyles
} from "@material-ui/core";
import { default as Layout } from "gatsby-theme-hypersite/layout";
import { getTimeSince, formatDate, slugify } from "../utils";
import { Content, PageHero } from "../components";

const ReleaseHistoryPage = ({ classes, hero, data, ...props }) => {
	const [expandedGroups, setExpandedGroups] = useState([]);
	const [expandedItems, setExpandedItems] = useState({});
	const [trackers, setTrackers] = useState([]);

	const { root, container, content, imageWrapper, image } = classes;
	const trackersNodes = data.allTrackers.nodes.filter(
		n => n.releases && n.releases.length > 0
	);

	useEffect(() => {
		setTrackers(
			trackersNodes.map((tracker, i) => ({
				...tracker,
				releases: tracker.releases
					.map((release, ii) => ({
						...release,
						date: new Date(release.date),
						id: i + ii / 10,
						open: false
					}))
					.sort((a, b) => a.date - b.date)
			}))
		);
		setExpandedItems(trackersNodes.map(n => []));
	}, []);

	const onExpandToggle = (i, ii) => {
		let newExpandedGroups = [...expandedGroups];
		let newExpandedItems = { ...expandedItems };
		newExpandedItems[i] = i in newExpandedItems ? newExpandedItems[i] : [];
		if (newExpandedItems[i].includes(ii)) {
			newExpandedItems[i] = newExpandedItems[i].filter(iii => iii !== ii);
		} else {
			newExpandedItems[i].push(ii);
		}

		if (newExpandedItems[i].length === trackers[i].releases.length) {
			newExpandedGroups.push(i);
		}
		if (newExpandedItems[i].length === 0) {
			newExpandedGroups = newExpandedGroups.filter(g => g !== i);
		}

		setExpandedGroups(newExpandedGroups);
		setExpandedItems(newExpandedItems);
	};

	const onExpandGroupToggle = i => {
		const groupReleases = trackersNodes[i];
		let newExpandedGroups = [...expandedGroups];
		let newExpandedItems = { ...expandedItems };
		if (expandedGroups.includes(i)) {
			newExpandedGroups = newExpandedGroups.filter(g => g !== i);
			newExpandedItems[i] = [];
		} else {
			newExpandedGroups.push(i);
			newExpandedItems[i] = groupReleases.releases.map((r, ii) => ii);
		}
		setExpandedGroups(newExpandedGroups);
		setExpandedItems(newExpandedItems);
	};

	return (
		<Layout {...props}>
			<Box className={clsx(classes.root)}>
				<Container maxWidth="lg">
					<Container maxWidth="md">
						{trackers
							.filter(t => t.releases.length > 0)
							.map((tracker, i) => (
								<Box key={i}>
									<Grid container spacing={3}>
										<Grid item sm={6}>
											<Box height="100%" display="flex">
												<Typography component="h3" variant="h4">
													Data Releases
												</Typography>
											</Box>
										</Grid>
										<Grid item sm={6}>
											<Button
												variant="contained"
												onClick={() => onExpandGroupToggle(i)}
											>
												<Fade in={!expandedGroups.includes(i)}>
													<Box>Expand all</Box>
												</Fade>
												<Fade in={expandedGroups.includes(i)}>
													<Box>Collapse all</Box>
												</Fade>
											</Button>
										</Grid>
									</Grid>
									<Box className={classes.releases}>
										{tracker.releases
											?.sort((a, b) => new Date(b.date) - new Date(a.date))
											?.map((r, ii) => {
												return (
													<Accordion
														square
														expanded={expandedItems[i].includes(ii)}
														onClick={() => onExpandToggle(i, ii)}
														key={ii}
													>
														<AccordionSummary>
															<Grid container spacing={3}>
																<Grid item xs={4}>
																	{r.name}
																</Grid>
																<Grid item xs={5}>
																	{r.fromNow}
																</Grid>
																<Grid item xs={3}>
																	{formatDate(r.date, {
																		year: "numeric",
																		month: "short",
																		date: "short"
																	})}
																</Grid>
															</Grid>
														</AccordionSummary>
														<AccordionDetails>
															<Typography>{r.summary}</Typography>
														</AccordionDetails>
													</Accordion>
												);
											})}
									</Box>
								</Box>
							))}
					</Container>
				</Container>
			</Box>
		</Layout>
	);
};

ReleaseHistoryPage.defaultProps = {
	hero: {
		title: "Release History",
		lede: "",
		image: "/images/hero-eviction.png"
	}
};

ReleaseHistoryPage.propTypes = {};

export default withStyles(theme => ({
	root: {
		background: theme.palette.background.paperSecondary,
		padding: theme.spacing(6, 0),
		"& .MuiTypography-h4": {
			margin: theme.spacing("auto", 0)
		},
		"& .MuiButton-root": {
			float: "right",
			"& .MuiButton-label": {
				width: theme.spacing(12),
				height: theme.spacing(2),
				position: "relative",
				textAlign: "center"
			},
			"& div": {
				position: "absolute",
				width: "100%",
				left: 0,
				top: 0
			},
			"& span > span": {
				opacity: 0,
				pointerEvents: "none"
			}
		},
		"& .mapboxgl-map": {
			margin: theme.spacing(10, 0)
		},
		"& .MuiAccordion-root": {
			background: theme.palette.background.paperSecondary
		},
		"& .MuiAccordionSummary-content .MuiGrid-item:last-child": {
			"&:last-child": {
				textAlign: "right"
			}
		}
	},
	releases: {
		paddingTop: theme.spacing(3),
		marginBottom: theme.spacing(12)
	}
}))(ReleaseHistoryPage);

export const pageQuery = graphql`
	{
		allTrackers {
			nodes {
				id
				description
				name
				releases {
					name
					date
					fromNow: date(fromNow: true)
					summary
				}
			}
		}
	}
`;
